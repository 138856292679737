exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-card-jsx": () => import("./../../../src/templates/card.jsx" /* webpackChunkName: "component---src-templates-card-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-imprint-jsx": () => import("./../../../src/templates/imprint.jsx" /* webpackChunkName: "component---src-templates-imprint-jsx" */),
  "component---src-templates-people-jsx": () => import("./../../../src/templates/people.jsx" /* webpackChunkName: "component---src-templates-people-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-services-jsx": () => import("./../../../src/templates/services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */),
  "component---src-templates-thanks-jsx": () => import("./../../../src/templates/thanks.jsx" /* webpackChunkName: "component---src-templates-thanks-jsx" */),
  "component---src-templates-work-jsx": () => import("./../../../src/templates/work.jsx" /* webpackChunkName: "component---src-templates-work-jsx" */)
}

