// extracted by mini-css-extract-plugin
export var black = "text-module--black--38038";
export var bold = "text-module--bold--31232";
export var grey = "text-module--grey--14ea9";
export var initial = "text-module--initial--67064";
export var lg = "text-module--lg--fdaac";
export var lowercase = "text-module--lowercase--0c8b6";
export var md = "text-module--md--32411";
export var normal = "text-module--normal--c8d0e";
export var primary = "text-module--primary--22233";
export var secondary = "text-module--secondary--be453";
export var sm = "text-module--sm--fac7f";
export var special = "text-module--special--5487a";
export var uppercase = "text-module--uppercase--770b5";
export var white = "text-module--white--eb39d";
export var xl = "text-module--xl--41e85";
export var xs = "text-module--xs--a1a23";
export var xxl = "text-module--xxl--5b529";
export var xxs = "text-module--xxs--6e538";
export var xxxl = "text-module--xxxl--42752";