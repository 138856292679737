import React from "react";
import { Link } from "gatsby";

import { Text, getProperty } from "@punkt/shared";

import { usePagesByMenu, MENU_MAIN } from "hooks";

import * as styles from "./nav-items.module.scss";

export const NavItems = () => {
  const pages = usePagesByMenu(MENU_MAIN);

  return pages.map((page) => {
    const path = getProperty(page, "path");
    const title = getProperty(page, "title");

    return (
      <Link key={path} to={path} className={styles.item} activeClassName={styles.active}>
        <Text size="xxl" color="white" weight="bold" transform="lowercase" wrapper="span">
          <span className={styles.text} data-title={title}>
            {title}
          </span>
        </Text>
      </Link>
    );
  });
};
