import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { getProperty, arrayToObject } from "@punkt/shared";

export const useProjects = () => {
  const ref = useRef(null);
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (ref.current === null) {
    ref.current = edges.map((project) => ({
      ...getProperty(project, "node.frontmatter.project"),
      ...{
        path: getProperty(project, "node.frontmatter.path"),
        meta: getProperty(project, "node.frontmatter.meta"),
        title: getProperty(project, "node.frontmatter.title"),
      },
    }));
  }

  return ref.current;
};

export const useProjectsByAlias = (value) => {
  const ref = useRef(null);
  const projects = useProjects();

  if (ref.current === null) {
    ref.current = arrayToObject(projects, "alias");
  }

  return value.map((alias) => ref.current[alias]);
};

export const useProjectByAlias = (value) => {
  const ref = useRef(null);
  const projects = useProjects();

  if (ref.current === null) {
    ref.current = arrayToObject(projects, "alias");
  }

  return ref.current[value];
};

const query = graphql`
  query useProjectsQuery {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "page" }, view: { eq: "project" } } }
      sort: { frontmatter: { project: { date: DESC } } }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            meta {
              title
              description
            }
            project {
              date
              alias
              summary {
                subline
                image {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
                video {
                  desktop {
                    publicURL
                    extension
                  }
                  mobile {
                    publicURL
                    extension
                  }
                  fallback {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
