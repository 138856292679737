import React from "react";

import { Text } from "@punkt/shared";

import { useFooter } from "hooks";

import * as styles from "./info.module.scss";

export const Info = () => {
  const { info } = useFooter();

  return (
    <div className={styles.wrapper}>
      <Text size="md" weight="bold">
        {info}
      </Text>
    </div>
  );
};
