import React, { Fragment, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { useTheme, useKeyPress } from "@punkt/shared";

import { Header } from "./header";
import { Footer } from "./footer";

import * as styles from "./layout.module.scss";

export const Layout = ({ children, ...props }) => {
  const { type: Type } = children;

  if (Type.disableLayout) {
    return <Fragment>{children}</Fragment>;
  }

  return <LayoutDelegate {...props}>{children}</LayoutDelegate>;
};

export const LayoutDelegate = ({ children, pageContext }) => {
  const [showMenu, setShowMenu] = useState(false);
  const isEscapePressed = useKeyPress("Escape");
  const { theme } = useTheme();

  const wrapperClassName = useMemo(() => [styles.wrapper, styles[theme]].join(" "), [theme]);
  const htmlAttributes = useMemo(
    () => ({
      class: [styles[theme], showMenu ? styles.overlay : ""].filter(Boolean).join(" "),
    }),
    [theme, showMenu]
  );

  const toggleHandler = () => setShowMenu(!showMenu);

  useEffect(() => {
    if (isEscapePressed) {
      setShowMenu(false);
    }
  }, [isEscapePressed]);

  useEffect(() => {
    setShowMenu(false);
  }, [pageContext.id]);

  useEffect(() => {
    const calculateAndApplyVh = () => {
      document.documentElement.style = `--vh: ${window.innerHeight * 0.01}px`;
    };

    window.addEventListener("orientationchange", calculateAndApplyVh);
    window.addEventListener("resize", calculateAndApplyVh);
    calculateAndApplyVh();

    return () => {
      window.removeEventListener("orientationchange", calculateAndApplyVh);
      window.removeEventListener("resize", calculateAndApplyVh);
    };
  }, []);

  return (
    <div className={wrapperClassName}>
      <Header showMenu={showMenu} onToggle={toggleHandler} />
      <main className={styles.main}>{children}</main>
      <Footer />
      <Helmet htmlAttributes={htmlAttributes} />
    </div>
  );
};

LayoutDelegate.defaultProps = {
  pageContext: {},
};

LayoutDelegate.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
  }).isRequired,
};
