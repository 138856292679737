import React from "react";
import { Link } from "gatsby";

import { Text, Markdown } from "@punkt/shared";

import { useHeader } from "hooks";

import * as styles from "./logo-large.module.scss";

export const LogoLarge = () => {
  const { logo_large } = useHeader();

  return (
    <Link to="/" className={styles.wrapper}>
      <Text weight="bold">
        <Markdown content={logo_large} />
      </Text>
    </Link>
  );
};
