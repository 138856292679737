// extracted by mini-css-extract-plugin
export var black = "button-module--black--3227c";
export var container = "button-module--container--2fe2f";
export var grey = "button-module--grey--5cf7f";
export var icon = "button-module--icon--f7679";
export var md = "button-module--md--9cdac";
export var primary = "button-module--primary--d47c9";
export var rounded = "button-module--rounded--da186";
export var secondary = "button-module--secondary--200a8";
export var sm = "button-module--sm--54947";
export var white = "button-module--white--4e38d";
export var wrapper = "button-module--wrapper--f6be3";
export var xs = "button-module--xs--4eb7c";