import React from "react";

import { LocaleProvider, ScrollProvider, ThemeProvider } from "@punkt/shared";

const wrapRootElement = ({ element }) => (
  <LocaleProvider>
    <ScrollProvider>
      <ThemeProvider>{element}</ThemeProvider>
    </ScrollProvider>
  </LocaleProvider>
);

export default wrapRootElement;
