import React from "react";
import { Link } from "gatsby";

import { Text, getProperty } from "@punkt/shared";

import { usePagesByMenu, MENU_LEGAL } from "hooks";

import * as styles from "./legal-items.module.scss";

export const LegalItems = () => {
  const pages = usePagesByMenu(MENU_LEGAL);

  return pages.map((page) => {
    const path = getProperty(page, "path");
    const title = getProperty(page, "title");

    return (
      <Link key={path} to={path} className={styles.item} activeClassName={styles.active}>
        <Text size="xxs" wrapper="span">
          {title}
        </Text>
      </Link>
    );
  });
};
