"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toUri = exports.isValid = void 0;
const isValid = value => {
  if (typeof value !== "string") {
    return false;
  }
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
};
exports.isValid = isValid;
const toUri = value => {
  return isValid(value) ? `mailto:${value}` : null;
};
exports.toUri = toUri;