import React from "react";

import { LegalItems } from "./legal-items";

import * as styles from "./legal.module.scss";

export const Legal = () => (
  <nav className={styles.wrapper}>
    <LegalItems />
  </nav>
);
