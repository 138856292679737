"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Conditional = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const Conditional = _ref => {
  let {
    condition,
    wrapper,
    children,
    fallbackWrapper
  } = _ref;
  return condition ? wrapper(children) : typeof fallbackWrapper === 'function' ? fallbackWrapper(children) : children;
};
exports.Conditional = Conditional;
Conditional.propTypes = {
  condition: _propTypes.default.any,
  fallbackWrapper: _propTypes.default.func,
  wrapper: _propTypes.default.func.isRequired,
  children: _propTypes.default.element.isRequired
};