import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Button, MenuIcon } from "@punkt/shared";

import { Nav } from "./nav";

export const Menu = ({ isOpen, onToggle }) => (
  <Fragment>
    <Button onClick={onToggle} icon={<MenuIcon />} />
    <Nav isOpen={isOpen} onClose={onToggle} />
  </Fragment>
);

Menu.defaultProps = {
  isOpen: false,
  onToggle: () => {},
};

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};
