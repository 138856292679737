import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { getProperty } from "@punkt/shared";

export const useMeta = () => {
  const ref = useRef(null);
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (ref.current === null) {
    const [edge] = edges.map((edge) => getProperty(edge, "node.frontmatter"));
    ref.current = edge;
  }

  return ref.current;
};

const query = graphql`
  query useMetaQuery {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "meta" } } }) {
      edges {
        node {
          frontmatter {
            meta {
              company
              error {
                title
                content
              }
              labels {
                page
                back_home
                save_contact
              }
            }
          }
        }
      }
    }
  }
`;
