// extracted by mini-css-extract-plugin
export var black = "title-module--black--0b513";
export var h1 = "title-module--h1--1efad";
export var h2 = "title-module--h2--e6260";
export var h3 = "title-module--h3--b58e4";
export var h4 = "title-module--h4--2fc00";
export var h5 = "title-module--h5--9be4e";
export var h6 = "title-module--h6--fa21d";
export var inherit = "title-module--inherit--ea122";
export var initial = "title-module--initial--65485";
export var lg = "title-module--lg--2d54e";
export var lowercase = "title-module--lowercase--24463";
export var md = "title-module--md--fd011";
export var noMargin = "title-module--no-margin--141b7";
export var primary = "title-module--primary--fab37";
export var sm = "title-module--sm--7302b";
export var special = "title-module--special--e72d7";
export var title = "title-module--title--304d3";
export var uppercase = "title-module--uppercase--d7dfb";
export var white = "title-module--white--cd568";
export var xl = "title-module--xl--c5e91";
export var xs = "title-module--xs--33edf";
export var xxl = "title-module--xxl--409a9";
export var xxs = "title-module--xxs--9a9c1";