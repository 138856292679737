// extracted by mini-css-extract-plugin
export var black = "section-module--black--d5e66";
export var center = "section-module--center--8ccde";
export var contentVisibility = "section-module--content-visibility--2d366";
export var isFullHeight = "section-module--is-full-height--34d8e";
export var left = "section-module--left--875c7";
export var lg = "section-module--lg--cfbb1";
export var md = "section-module--md--170b3";
export var noPaddingBottom = "section-module--no-padding-bottom--da4a5";
export var noPaddingTop = "section-module--no-padding-top--ff7e1";
export var primary = "section-module--primary--fe614";
export var right = "section-module--right--7e424";
export var sm = "section-module--sm--2fa86";
export var transparent = "section-module--transparent--1bd6b";
export var white = "section-module--white--f5081";
export var wrapper = "section-module--wrapper--cfc5f";