import React from "react";
import PropTypes from "prop-types";

import { Container } from "@punkt/shared";

import { Logo } from "./header/logo";
import { Menu } from "./header/menu";

import * as styles from "./header.module.scss";

export const Header = ({ showMenu, onToggle }) => (
  <header className={styles.wrapper}>
    <Container>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.menu}>
          <Menu isOpen={showMenu} onToggle={onToggle} />
        </div>
      </div>
    </Container>
  </header>
);

Header.defaultProps = {
  showMenu: false,
  onToggle: () => {},
};

Header.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};
