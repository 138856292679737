import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { getProperty } from "@punkt/shared";

export const useHeader = () => {
  const ref = useRef(null);
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (ref.current === null) {
    const [edge] = edges.map((edge) => getProperty(edge, "node.frontmatter"));
    ref.current = edge;
  }

  return ref.current;
};

const query = graphql`
  query useHeaderQuery {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "header" } } }) {
      edges {
        node {
          frontmatter {
            logo_small
            logo_large
          }
        }
      }
    }
  }
`;
