"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMatchMedia = void 0;
var _react = require("react");
const useMatchMedia = query => {
  const [match, setMatch] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    const handler = _ref => {
      let {
        matches
      } = _ref;
      return setMatch(matches);
    };
    const mql = window.matchMedia(query);
    mql.addEventListener("change", handler);
    handler(mql);
    return () => mql.removeEventListener("change", handler);
  }, [query]);
  return match;
};
exports.useMatchMedia = useMatchMedia;