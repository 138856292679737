"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XS = exports.XL = exports.SM = exports.MD = exports.LG = void 0;
var _breakpointsModule = require("./breakpoints.module.scss");
// parse SASS variables and convert rem to px: "1rem" -> 16
const XS = 16 * parseFloat(_breakpointsModule.breakpoint_xs);
exports.XS = XS;
const SM = 16 * parseFloat(_breakpointsModule.breakpoint_sm);
exports.SM = SM;
const MD = 16 * parseFloat(_breakpointsModule.breakpoint_md);
exports.MD = MD;
const LG = 16 * parseFloat(_breakpointsModule.breakpoint_lg);
exports.LG = LG;
const XL = 16 * parseFloat(_breakpointsModule.breakpoint_xl);
exports.XL = XL;