"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.debounce = debounce;
function debounce(callback) {
  let wait = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 300;
  let immediate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let timeout = null;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    const callNow = immediate && !timeout;
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
    if (callNow) {
      next();
    }
  };
}