import React, { useState, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

import { Button, Container, CloseIcon } from "@punkt/shared";

import { NavItems } from "./nav-items";

import { LogoLarge } from "./logo-large";

import * as styles from "./nav.module.scss";

export const Nav = ({ isOpen, onClose }) => {
  const [renderPortal, setRenderPortal] = useState(false);
  const wrapperClassName = useMemo(
    () => [styles.wrapper, isOpen ? styles.open : ""].filter(Boolean).join(" "),
    [isOpen]
  );

  useEffect(() => {
    setRenderPortal(true);
  }, []);

  return renderPortal
    ? createPortal(
        <nav className={wrapperClassName}>
          <Container>
            <div className={styles.top}>
              <div className={styles.clickable}>
                <LogoLarge />
              </div>
              <div className={styles.clickable}>
                <Button onClick={onClose} icon={<CloseIcon />} />
              </div>
            </div>
            <div className={styles.bottom}>
              <NavItems />
            </div>
          </Container>
        </nav>,
        document.body
      )
    : null;
};

Nav.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

Nav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
