import React from "react";

import { SocialMediaItems } from "./social-media-items";

import * as styles from "./nav.module.scss";

export const SocialMedia = () => (
  <nav className={styles.wrapper}>
    <SocialMediaItems />
  </nav>
);
