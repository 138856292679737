import React from "react";
import { Link } from "gatsby";

import { Text } from "@punkt/shared";

import { useHeader } from "hooks";

import * as styles from "./logo.module.scss";

export const Logo = () => {
  const { logo_small } = useHeader();

  return (
    <Link to="/" className={styles.wrapper}>
      <Text size="md" weight="bold">
        {logo_small}
      </Text>
    </Link>
  );
};
