import React from "react";

import { Text, getProperty } from "@punkt/shared";

import { useFooter } from "hooks";

import * as styles from "./social-media-items.module.scss";

export const SocialMediaItems = () => {
  const { social_media } = useFooter();

  return social_media.map((item) => {
    const name = getProperty(item, "name");
    const path = getProperty(item, "path");

    return (
      <a key={path} href={path} className={styles.item} target="_blank" rel="noreferrer">
        <Text size="xs" transform="lowercase" wrapper="span">
          {name}
        </Text>
      </a>
    );
  });
};
