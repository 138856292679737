import React from "react";

import { Container } from "@punkt/shared";

import { Info } from "./footer/info";
import { Nav } from "./footer/nav";
import { Contact } from "./footer/contact";
import { Legal } from "./footer/legal";
import { SocialMedia } from "./footer/social-media";

import * as styles from "./footer.module.scss";

export const Footer = () => (
  <footer id="contact" className={styles.wrapper}>
    <Container>
      <div className={styles.top}>
        <Info />
      </div>
      <div className={styles.center}>
        <div className={styles.contact}>
          <Contact />
        </div>
        <div className={styles.nav}>
          <Nav />
        </div>
        <div className={styles.socialMedia}>
          <SocialMedia />
        </div>
      </div>
      <div className={styles.bottom}>
        <Legal />
      </div>
    </Container>
  </footer>
);
