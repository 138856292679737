import React from "react";

import { Text, Markdown, toEmailUri, toPhoneUri } from "@punkt/shared";

import { useFooter } from "hooks";

import * as styles from "./contact.module.scss";

export const Contact = () => {
  const { email, phone, address } = useFooter();

  return (
    <div className={styles.wrapper}>
      <Text>
        <a href={toEmailUri(email)} className={styles.email}>
          {email}
        </a>
        <a href={toPhoneUri(phone)} className={styles.phone}>
          {phone}
        </a>
        <address className={styles.address}>
          <Markdown content={address} />
        </address>
      </Text>
    </div>
  );
};
